import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";
import Page from "../../components/Layout/Page";
import CasestudiesCarousel from "../../components/CaseStudies";
import Customers from "../../components/Customers";
import Container from "../../components/Layout/Container";
import BtnFilled from "../../components/reusable/BtnFilled";
import ContactBlock from "../../components/ContactBlock";

import Seo from "../../components/reusable/Seo";

const DataEnggPage = () => {
  return (
    <Page className="solutions data-engg">
      <Seo
        title="Data Engineering"
        path="solutions/data-engineering"
        description=" Build scalable and distribute data platforms to enable
        AI-ML capabilities"
      />
      <section className="hero">
        <Container>
          <h1>Break Silos &amp; Unleash Your Data at Scale</h1>
          <BtnFilled to="/contact-us" color="blue">
            Get in Touch
          </BtnFilled>
        </Container>
      </section>
      <section className="overview">
        <Container>
          <p className="intro">
            <b>
              Break the silos to unify data sources and build future ready data
              platform
            </b>
          </p>
          <p>
            Our Data Platforms for Ecommerce Solutions and Data Lake, and
            pre-configured templated for Data Pipeline enable you to set-up a
            strong data foundation to drive data driven decisions and build
            strong AI-ML core on top of it. Setting up the initial data
            foundation is crucial to future use-cases and scalability of the
            solution.
          </p>
          <p>
            With industry experience, and deep technical expertise our
            accelerators can deliver data solutions 40-60% faster than building
            the capabilities from scratch in-house.
          </p>
        </Container>
      </section>
      <section className="accelerators">
        <Container>
          <h2>
            <b>Build a strong data foundation</b>
          </h2>
          <div className="items">
            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/data--engg.png"
                    alt="Deep Data Engineering Capabilities -  Inviz AI"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="none"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>Data Platforms</b>
                  </h3>
                  <p>
                    Accelerate your data platform journey would our ecommerce
                    and data lake solutions.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/data--fast.png"
                    alt="Enterprise ready data platform - Inviz AI"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="none"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>Get up and running up to 60% faster</b>
                  </h3>
                  <p>
                    Set-up enterprise ready data platform from scratch in a few
                    weeks.
                  </p>
                </div>
              </div>
            </div>

            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/data--automation.png"
                    alt="Data Engineering - Full Automation - Inviz AI"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="none"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>Full Automation</b>
                  </h3>
                  <p>
                    Automate data ingestion with our data platforms and
                    pre-configured templates for data pipeline for AI-ML.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="case-studies">
        <Container>
          <h2>
            <b>Case Studies</b>
          </h2>
          <CasestudiesCarousel />
        </Container>
      </section>
      <section className="solutions">
        <Container>
          <h2>
            <b>Solutions</b>
          </h2>
          <div className="items">
            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/graphics/solutions-vectors_data--infrastruture-development.png"
                    alt="Data Infrastructure Development"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="blurred"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>Data Platforms</b>
                  </h3>
                  <p>Ecommerce Solutions &#183; Data Lake</p>
                </div>
              </div>
            </div>
            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/graphics/solutions-vectors_data--consulting.png"
                    alt="Data Consulting"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="blurred"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>Data Pipelines for AI-ML</b>
                  </h3>
                  <p>Pre-configured Templates &#183; Custom Pipelines</p>
                </div>
              </div>
            </div>
            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/graphics/solutions-vectors_data--set-up-governance.png"
                    alt="Data Infrastructure Development"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="blurred"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>Data Governance &amp; Security</b>
                  </h3>
                  <p>Assessment &#183; Encryption &#183; Monitoring</p>
                </div>
              </div>
            </div>

            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/graphics/solutions-vectors_data--applications-analytics.png"
                    alt="Data Applications and Analytics"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="blurred"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>Data Driven Decisions</b>
                  </h3>
                  <p>Analytics &#183; Dashboard &#183; Data Applications</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <ContactBlock>
        Talk to a data engineering expert o find the right solution for your
        use-case
      </ContactBlock>
      <section className="clients">
        <Container>
          <h2>
            <b>Our Customers</b>
          </h2>
          <Customers />
        </Container>
      </section>
    </Page>
  );
};

export default DataEnggPage;
